import React, { Dispatch, useContext } from "react";
import { useSmartObject, useConnection } from "@eveworld/contexts";
import { useMUD } from "../MUDContext";
import { EveButton } from "@eveworld/ui-components";

import { SpeedRacerContext } from "../src/contract/SpeedRacerContext.tsx";
//import { getKeyTuple } from "@latticexyz/protocol-parser/internal";
import { Address } from "viem";
import { State } from "@eveworld/types";

interface Props {
  inventoryList: JSX.Element[];
}

const InventoryListView = ({ inventoryList }: Props) => {
  //const { smartCharacter } = useSmartObject();
  const { SpeedRacerContract } = useContext(SpeedRacerContext);
  const { smartAssembly } = useSmartObject();
  const { walletClient, publicClient } = useConnection();

  const {
    network: { tables, useStore },
  } = useMUD();

  const highScoreEntities = useStore((state) => {
    var records = Object.values(state.getRecords(tables.RickRolled));
    return records;
  });

  var errorMessage = "";

  var smartCharacterIds: Address[] = [];

  highScoreEntities.forEach((record) => {
    //var x = getKeyTuple(tables.MiningChampions2, record.key);
    smartCharacterIds.push(record.fields.smartCharacterId);
    //console.log(x[0]);
  });

  //console.log(import.meta.env.VITE_TAPTICC_ADDRESS);

  const handleDeletePoints = async () => {
    if (highScoreEntities.length == 0) return;

    const getTransactionRequest = async () => {
      if (
        !walletClient ||
        !walletClient?.account ||
        !publicClient ||
        !SpeedRacerContract ||
        !smartAssembly
      )
        return;

      return publicClient.simulateContract({
        address: SpeedRacerContract.address,
        abi: SpeedRacerContract.abi,
        functionName: "TurnCoat__deleteSpeedRacer",
        args: [BigInt(smartAssembly.id.toString()), smartCharacterIds],
        account: walletClient.account.address,
      });
    };

    if (
      !walletClient ||
      !walletClient.account ||
      !SpeedRacerContract ||
      !smartAssembly
    )
      return;

    try {
      const request = getTransactionRequest();

      if (!request) return; //return if simulateContract failed

      //console.info("");

      if (walletClient.account) {
        walletClient.writeContract({
          address: SpeedRacerContract.address,
          abi: SpeedRacerContract.abi,
          functionName: "TurnCoat__deleteSpeedRacer",
          args: [BigInt(smartAssembly.id.toString()), smartCharacterIds],
          account: walletClient.account.address,
          chain: walletClient.chain,
        });
      }

      errorMessage = "Process complete, leaderboard is cleared!";
      //console.info(errorMessage);

      return;
    } catch (e: any) {
      errorMessage = e.message;
      console.error(errorMessage);
    }
  };

  if (
    // smartAssembly?.ownerId.toLocaleLowerCase() ==
    // walletClient?.account?.address.toLocaleLowerCase()
    walletClient?.account?.address.toLocaleLowerCase() ==
    import.meta.env.VITE_TAPTICC_ADDRESS.toLocaleLowerCase()
  ) {
    return (
      <>
        <div className="grid grid-cols-2 mobile:grid-cols-1 bg-crude">
          <div className="text-l my-2 text-center">
            You have the following items safely stored in this SSU:
            <EveButton
              typeClass="primary"
              className="primary-sm"
              style={{
                width: "175px",
              }}
              onClick={() => handleDeletePoints()}
              disabled={false}
              id="DeletePoints"
            >
              Delete Entry
            </EveButton>
          </div>
          <div className="contents empty-span-full text-xs">
            <table style={{ width: "100%" }}>
              <tbody>{inventoryList}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="grid grid-cols-2 mobile:grid-cols-1 bg-crude">
          <div className="text-l my-2 text-center">
            You have the following items safely stored in this SSU:
          </div>
          <div className="contents empty-span-full text-xs">
            <table style={{ width: "100%" }}>
              <tbody>{inventoryList}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
};

export default React.memo(InventoryListView);
