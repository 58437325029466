import { ThemeProvider, createTheme } from "@mui/material";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { EveWorldProvider } from "@eveworld/contexts";
import { ErrorNotice, ErrorNoticeTypes } from "@eveworld/ui-components";

import EntityView from "./components/EntityView";

import { App } from "./App";
import React from "react";
import { MUDProvider } from "./MUDContext";
import SpeedRacerProvider from "./src/contract/SpeedRacerContext";
import { setup } from "./mud/setup";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "0px !important",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          letterSpacing: 0,
        },
        message: {
          padding: "8px 16px !important",
        },
      },
    },
  },
});

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "*",
        element: <EntityView />,
      },
    ],
  },
]);

setup().then(async (result) => {
  ReactDOM.createRoot(document.getElementById("root")!).render(
    <ErrorBoundary
      fallback={
        <ErrorNotice
          type={ErrorNoticeTypes.MESSAGE}
          errorMessage="Anomaly detected: See developer console for details"
        />
      }
    >
      <EveWorldProvider>
        <SpeedRacerProvider>
          <ThemeProvider theme={darkTheme}>
            <MUDProvider value={result}>
              <RouterProvider router={router} />
            </MUDProvider>
          </ThemeProvider>
        </SpeedRacerProvider>
      </EveWorldProvider>
      ,
    </ErrorBoundary>
  );
});
