const ISpeedRacer = {
    address: "0x5a5a7689d2baf7e8a74fa73ea47493a0bf4deb2fcac3c962643a02c77c53a744",
    abi: [
      {
        type: "function",
        name: "TurnCoat__executeSpeedRacer",
        inputs: [
          {
            name: "smartAssemblyId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "quantity",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "itemId",
            type: "uint256",
            internalType: "uint256",
          },
          {
            name: "characterName",
            type: "string",
            internalType: "string",
          },
        ],
        outputs: [],
        stateMutability: "nonpayable",
      },
      {
        type: "function",
        name: "TurnCoat__deleteSpeedRacer",
        inputs: [
          {
            name: "smartAssemblyId",
            type: "uint256",
            internalType: "uint256",
          },   
          {
            name: "smartCharacterIds",
            type: "address[]",
            internalType: "address[]",
          },        
        ],
        outputs: [],
        stateMutability: "nonpayable",
      }
    ]
  } as const;
   
  export default ISpeedRacer;
  