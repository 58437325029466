import { defineWorld } from "@latticexyz/world";

export default defineWorld({
  namespace: "TurnCoat",
  tables: {    
    SpeedRacer: {
      schema: {
        smartCharacterId: "address",
        smartObjectId: "uint256",
        unitsAtWaypoint: "uint256",
        timeAtWaypoint: "uint256",
      },
      key: ["smartCharacterId", "smartObjectId"],
    },
    RickRolled: {
      schema: {
        smartCharacterId: "address",
        points: "uint256",
        characterName: "string",
      },
      key: ["smartCharacterId"],
    },
  },
});
