import React, { useState } from "react";

import { useSmartObject, useConnection } from "@eveworld/contexts";

const WelcomePlayer = React.memo(() => {
  const { smartCharacter } = useSmartObject();
  useConnection();

  if (smartCharacter === undefined) return <>undefined</>;
  if (smartCharacter.name === "") return <>Your Character Name Is Blank!</>;

  const [welcomeMessage] = useState(
    "Welcome " + smartCharacter.name + ", to Fuel Trader!"
  );

  return (
    <>
      <div
        className="Quantum-Container font-semibold grid grid-cols-3 gap-2 text-center"
        style={{ backgroundColor: "black" }}
        id="SmartObject-Actions"
      >
        {welcomeMessage}
      </div>

      <div
        className="Quantum-Container font-normal text-xs !py-4"
        id="instructions-description"
        style={{ backgroundColor: "black" }}
      >
        {/* You must race to locate 5 Waypoints hidden in the current system. Drop
        100 units of any item into each Waypoint storage container and claim
        your ticket. Once you have claimed a ticket from each of the 5
        Waypoints, your sprint time will be recorded! You can reset your current
        run at any time to start again, and your score is based on the time of
        your 1st ticket claim to your last. Good luck rider, happy sprinting! */}
        Use this Smart Unit to swap either 10 units of Salvaged Exotronics for
        300 EU 40 Fuel, or 20 units of Salvaged Exotronics for 300 EO 90 Fuel
      </div>
    </>
  );
});

export default React.memo(WelcomePlayer);
