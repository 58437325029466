import React, { useEffect, useState } from "react";

import { useSmartObject, useNotification } from "@eveworld/contexts";
import { Severity, SmartAssemblyType } from "@eveworld/types";
import {
  EveLoadingAnimation,
  ErrorNotice,
  ErrorNoticeTypes,
} from "@eveworld/ui-components";
import WelcomePlayer from "./WelcomePlayer";
import { useMUD } from "../MUDContext";

import { JSX } from "react/jsx-runtime";
import { findOwnerByAddress } from "@eveworld/utils";
import InventoryListItem from "./InventoryListItem";
import InventoryListView from "./InventoryListView";

import YouTube, { YouTubeProps } from "react-youtube";
//import ReactPlayer from "react-player/youtube";

// export interface HasClaimedContextInterface {
//   hasClaimed: boolean;
//   setHasClaimed: Dispatch<SetStateAction<boolean>>;
// }

// const defaultState = {
//   hasClaimed: false,
//   setHasClaimed: (admin: boolean) => {},
// } as HasClaimedContextInterface;

// export const HasClaimedContext = createContext(defaultState);

export interface State {
  hasClaimed: boolean;
}

const EntityView = React.memo((): JSX.Element => {
  //const { defaultNetwork, gatewayConfig } = useConnection();
  const { smartAssembly, smartCharacter, loading } = useSmartObject();
  const { notify, handleClose } = useNotification();
  const {
    network: { tables, useStore },
  } = useMUD();

  const [isShowingHighScores, setIsShowingHighScores] = useState(false);

  const isShowingHighScoresChange = () => {
    setIsShowingHighScores(!isShowingHighScores);
  };

  const [state, setState] = useState<State>({
    hasClaimed: false,
  });

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    var embedCode = event.target.getVideoEmbedCode();
    event.target.pauseVideo();
    const check = document.getElementById("embed-code");
    if (check) {
      check.innerHTML = embedCode;
    }
  };

  const opts: YouTubeProps["opts"] = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      // hostname: "https://www.youtube.com",
      // origin: "https://tapticc-dapp.pages.dev",
    },
  };

  useEffect(() => {
    if (loading) {
      notify({ type: Severity.Info, message: "Preparing..." });
    } else {
      handleClose();
    }
  }, [loading]);

  if ((!loading && !smartAssembly) || !smartAssembly?.isValid) {
    return <ErrorNotice type={ErrorNoticeTypes.SMART_ASSEMBLY} />;
  }

  var smartStorageUnit = smartAssembly as SmartAssemblyType<"SmartStorageUnit">;

  var ephemeralInventoryList =
    smartStorageUnit.inventory.ephemeralInventoryList;

  const playerInventory = ephemeralInventoryList.find((x) =>
    findOwnerByAddress(x.ownerId, smartCharacter.address)
  );

  const inventoryList: JSX.Element[] = [];
  if (playerInventory) {
    var ephemeralInventoryItems = playerInventory.ephemeralInventoryItems;
    if (ephemeralInventoryItems) {
      ephemeralInventoryItems.forEach((ephemeralInventoryItem) => {
        inventoryList.push(
          <InventoryListItem
            ephemeralInventoryItem={ephemeralInventoryItem}
            key={ephemeralInventoryItem.itemId}
            setState={setState}
          ></InventoryListItem>
        );
      });
    } else {
      inventoryList.push(
        <li key="ephemeralInventoryItemsNotSet">
          You have no Salvaged Exotronics in this SSU, start depositing now and
          get claiming fuel!
        </li>
      );
    }
  } else {
    inventoryList.push(
      <li key="playerInventoryNotSet">
        You have no Salvaged Exotronics in this SSU, start depositing now and
        get claiming fuel!
      </li>
    );
  }

  return (
    // <HasClaimedContext.Provider value={{ hasClaimed, setHasClaimed }}>
    <EveLoadingAnimation position="vertical">
      <div className="grid border border-brightquantum">
        {/* <div
          className="flex flex-col align-center border border-brightquantum"
          id="smartassembly-name"
        ></div> */}
        <WelcomePlayer></WelcomePlayer>
        {!isShowingHighScores && (
          <InventoryListView inventoryList={inventoryList}></InventoryListView>
        )}
        {state.hasClaimed && (
          // <iframe
          //   width="560"
          //   height="315"
          //   src="https://www.youtube.com/v/xvFZjo5PgG0"
          //   data-allow="autoplay; encrypted-media"
          //   allowFullScreen
          // ></iframe>
          // <YouTube
          //   videoId="xvFZjo5PgG0"
          //   options={opts}
          //   onReady={onPlayerReady}
          //   id="video"
          // />

          // <ReactPlayer url="https://www.youtube.com/watch?v=xvFZjo5PgG0" />

          // <iframe
          //   id="ytplayer"
          //   width="640"
          //   height="360"
          //   src="https://www.youtube.com/embed/xvFZjo5PgG0?autoplay=1&origin=https://tapticc-dapp.pages.dev/"
          // ></iframe>

          <iframe
            width="640"
            height="360"
            src="https://www.youtube.com/embed/xvFZjo5PgG0"
            title="Rick Roll (Different link + no ads)"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            referrerPolicy="strict-origin-when-cross-origin"
          ></iframe>
        )}
      </div>
    </EveLoadingAnimation>
    // </HasClaimedContext.Provider>
  );
});

export default React.memo(EntityView);
