import React, { useContext } from "react";
import { InventoryItem, SmartAssemblyType } from "@eveworld/types";
import { EveButton } from "@eveworld/ui-components";

import { findOwnerByAddress } from "@eveworld/utils";
import { useSmartObject, useConnection } from "@eveworld/contexts";

import { SpeedRacerContext } from "../src/contract/SpeedRacerContext.tsx";
import { State } from "./EntityView.tsx";

//import { HasClaimedContext } from "./EntityView.tsx";

interface Props {
  ephemeralInventoryItem: InventoryItem;
  setState: React.Dispatch<React.SetStateAction<any>>;
}

const InventoryListItem: React.FC<Props> = ({
  ephemeralInventoryItem,
  setState,
}: Props) => {
  const { smartCharacter } = useSmartObject();
  const { SpeedRacerContract } = useContext(SpeedRacerContext);
  const { smartAssembly } = useSmartObject();
  const { walletClient, publicClient } = useConnection();
  //const [hasClaimed, setHasClaimed] = useState(false);

  var errorMessage = "";

  var smartStorageUnit = smartAssembly as SmartAssemblyType<"SmartStorageUnit">;

  var ephemeralInventoryList =
    smartStorageUnit.inventory.ephemeralInventoryList;

  const playerInventory = ephemeralInventoryList.find((x) =>
    findOwnerByAddress(x.ownerId, smartCharacter.address)
  );

  if (smartAssembly === undefined || smartAssembly === null) return <></>;

  const handleClaimFuel = async (itemId: number) => {
    const getTransactionRequest = async (
      ephemeralInventoryItem: InventoryItem
    ) => {
      if (
        !walletClient ||
        !walletClient?.account ||
        !publicClient ||
        !SpeedRacerContract
      )
        return;

      return publicClient.simulateContract({
        address: SpeedRacerContract.address,
        abi: SpeedRacerContract.abi,
        functionName: "TurnCoat__executeSpeedRacer",
        args: [
          BigInt(smartAssembly.id.toString()),
          BigInt(ephemeralInventoryItem.quantity.toString()),
          BigInt(ephemeralInventoryItem.itemId.toString()),
          String(smartCharacter.name),
        ],
        account: walletClient.account.address,
      });
    };

    if (!walletClient || !walletClient.account || !SpeedRacerContract) return;

    if (playerInventory) {
      var ephemeralInventoryItems = playerInventory.ephemeralInventoryItems;
      if (ephemeralInventoryItems) {
        try {
          ephemeralInventoryItems.forEach((ephemeralInventoryItem) => {
            if (
              ephemeralInventoryItem.itemId == itemId &&
              ephemeralInventoryItem.quantity > 0
            ) {
              const request = getTransactionRequest(ephemeralInventoryItem);

              if (!request) return; //return if simulateContract failed

              // console.info(
              //   ephemeralInventoryItem.quantity.toString() +
              //     " of " +
              //     ephemeralInventoryItem.itemId.toString()
              // );

              if (walletClient.account) {
                walletClient.writeContract({
                  address: SpeedRacerContract.address,
                  abi: SpeedRacerContract.abi,
                  functionName: "TurnCoat__executeSpeedRacer",
                  args: [
                    BigInt(smartAssembly.id.toString()),
                    BigInt(ephemeralInventoryItem.quantity.toString()),
                    BigInt(ephemeralInventoryItem.itemId.toString()),
                    String(smartCharacter.name),
                  ],
                  account: walletClient.account.address,
                  chain: walletClient.chain,
                });
              }
            }
          });

          errorMessage =
            "Process complete, check the leader board to see how you compare!";
          //console.info(errorMessage);

          setState((c) => {
            return {
              ...c,
              hasClaimed: true,
            };
          });

          //setHasClaimed(true);

          return;
        } catch (e: any) {
          errorMessage = e.message;
          // console.error(errorMessage);
        }
      }
    }
  };

  return (
    <>
      <tr style={{ width: "100%" }}>
        <td style={{ width: "80px", height: "80px", paddingLeft: "15px" }}>
          <img
            src={ephemeralInventoryItem.image.replace(
              "https://sandbox-garnet-ipfs-gateway.nursery.reitnorf.com/ipfs/",
              "https://mainnet-game-ipfs-gateway.nursery.reitnorf.com/ipfs/"
            )}
          ></img>
        </td>
        <td
          style={{
            width: "calc(100% - 200px)",
            height: "80px",
            paddingLeft: "15px",
          }}
        >
          {ephemeralInventoryItem.quantity} units of{" "}
          {ephemeralInventoryItem.name}
        </td>
        <td>
          <EveButton
            typeClass="primary"
            className="primary-sm"
            style={{
              width: "175px",
            }}
            onClick={() => handleClaimFuel(ephemeralInventoryItem.itemId)}
            disabled={false}
            id="ClaimFuel"
          >
            {/* Claim {Math.floor(ephemeralInventoryItem.quantity)} Point(s) */}
            Claim Fuel
          </EveButton>
        </td>
      </tr>
    </>
  );
};

export default InventoryListItem;
